import type { CheckboxChangeEvent } from 'antd/lib/checkbox';
import type { BaseData, PaginationData } from 'egenie-common';
import { request } from 'egenie-common';
import { action, observable } from 'mobx';
import { api } from '../../../utils';

export default class NotificationCenterStore {
  @observable public messageType = '0'; // 1.订单通知 2.售后单

  @observable public loading = false;

  @observable public page = 1;

  @observable public pageSize = 20;

  @observable public total = 0;

  @observable public data = [];

  @observable public readAll = false; // 是否已发送全部已读请求

  @observable public isRead = false;

  @observable public messageTypeList = [
    {
      label: '全部',
      value: '0',
      count: 0,
    },
    {
      label: '订单通知',
      value: '1',
      count: 0,
    },
    {
      label: '售后单通知',
      value: '2',
      count: 0,
    },
  ];

  @observable public queryConditions = {
    page: 1,
    pageSize: 20,
    sidx: '',
    sord: '',
    type: undefined,
  };

  @action public getUnreadListNum = (): void => {
    request<BaseData<Array<{ unReadCount: number;businessType: number; }>>>({ url: api.getUnReadList }).then((res) => {
      let unreadNum = 0;
      let messageTypeList = this.messageTypeList;
      messageTypeList = this.messageTypeList.map((item) => {
        const it = res.data.find((v) => `${v.businessType}` === item.value);
        if (it) {
          item.count = it.unReadCount;
        }
        return item;
      });
      res.data?.map((item) => {
        unreadNum += item.unReadCount;
      });
      messageTypeList[0].count = unreadNum;
      this.messageTypeList = messageTypeList;
    });
  };

  @action public onChangeActiveTab = (activeKey: string): void => {
    this.messageType = activeKey;
    this.page = 1;
    this.queryMessageList();
  };

  @action public queryMessageList = async(params?): Promise<void> => {
    const res: PaginationData = await request({
      url: api.getBossMessageList,
      method: 'POST',
      data: {
        businessType: this.messageType,
        page: this.page,
        pageSize: this.pageSize,
        status: this.isRead ? 0 : undefined,
        ...params,
      },
    });
    this.total = res.data.totalCount;
    this.data = res.data.list;
    this.getUnreadListNum();
  };

  @action public onPaginationChange = (page: number, pageSize: number): void => {
    this.page = page;
    this.pageSize = pageSize;
    this.queryMessageList();
  };

  @action public changeCheckBox = (e: CheckboxChangeEvent) => {
    const { checked } = e.target;
    this.isRead = checked;
    const params = {
      unReadMessage: checked,
      page: 1,
      pageSize: 20,
    };
    this.queryMessageList(params);
  };

  @action public setAllRead = () => {
    this.hasReadMessage(null);
  };

  @action public hasReadMessage = async(id: number): Promise<void> => {
    await request({
      url: '/api/boss/baseinfo/rest/message/done',
      params: { id },
    });
    this.page = 1;
    this.queryMessageList({
      page: 1,
      pageSize: 50,
    });
  };
}
