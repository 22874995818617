import { getStaticResourceUrl } from 'egenie-common';

export const api = {
  // 静态资源
  oss: getStaticResourceUrl('pc/ts/egenie-common'),

  // 导航
  menuList: '/api/iac/resource/dashboard/dock2', // 菜单
  menuSource: '/api/iac/resource/getResource', // 单个菜单数据

  // 用户信息
  user: '/api/dashboard/user', // 用户
  changePassword: '/api/iac/user/changePassword', // 修改密码
  userPerms: '/api/iac/role/user/perms', // 按钮权限列表
  announcement: 'announcement/findAnnouncements', // 系统更新
  tenantRechangeStatistics: '/api/tenant/tenantRecharge/statistics', // 获取余量数据

  // 登录
  login: '/user_login', // 登录
  getAuthImage: '/api/iac/authImage/anon/getAuthImage', // 获取图片验证码

  // 字典
  getDict: '/api/gim/anon/dict', // {type}
  findDict: '/api/iac/anon/dict', // {codeType}

  // 注册
  marketList: '/api/gim/rest/anon/market/findAllMarket', // 市场列表
  register: '/api/iac/user/anon/register', // 注册新用户
  registerRetailer: '/api/iac/register/anon/retailer', // 注册零售商
  registerSupplier: '/api/iac/register/anon/supplier', // 注册供应商
  findMarketCity: '/api/gim/rest/anon/market/findMarketCity', // 查城市
  findByCityIds: '/api/gim/rest/anon/market/findByCityIds', // 根据城市查市场
  findFloor: '/api/gim/anon/floor/findFloor', // 查楼层
  queryRepeatShop: '/api/pos/baseinfo/rest/shop/anon/queryRepeatShop', // 检查档口好是否重复
  findOneLevel: '/api/gim/category/anon/findOneLevel', // 获取主营分类【一级】
  userCheck: '/api/iac/user/check', // 验证手机号是否存在
  findProvince: '/api/iac/register/anon/province', // 省
  findCityByProvince: '/api/iac/register/anon/city', // 市
  findDistrictByCity: '/api/iac/register/anon/district', // 区

  // 忘记密码
  checkUserName: '/api/iac/user/anon', // 验证用户是否存在
  sendCode: '/api/iam/validCode/anon/send', // 发送验证码
  validateCode: '/api/iac/validCode/anon/password/reset/validate', // 验证手机号验证码
  newPassword: '/api/iac/user/anon/password/reset', // 修改密码

  // erp dashboard
  salegoods: '/api/bi/erp_current/orders/indexTimeline/6/7', // 销量
  delivergoods: '/api/bi/erp_current/orders/delivergoods/6/7', // 发货量
  todaygoods: '/api/bi/erp_current/orders/delivergoods/11/24', // 当天发货
  payedAndNotDelivery: '/api/bi/erp_current/rss/isPaidNotCheck/queryIndex', // 已付款未发货
  getSevenDaysTopProducts: '/api/bi/saleStatistics/getSevenDaysTopProducts', // 销售前几名
  getWaybillNotice: '/api/wms/rest/waybill/getWaybillNotice', //
  queryCommonInfo: '/api/baseinfo/rest/homepage/queryCommonInfo', // 查询公告、更新、快捷方式
  commitFeedback: '/api/baseinfo/rest/homepage/commitFeedback', // 反馈
  saveShortcut: '/api/baseinfo/rest/homepage/saveShortcut', // 修改快捷方式
  orderTodo: '/api/oms/rest/monitor/undeliveredAmounts', // 订单待办
  deliveryTodo: '/api/bi/erp_current/menu', // 发货单待办
  switchToXuankuan: '/api/iam/module/switch', // 切换到选款pc前台
  queryPromotionGoods: '/api/mall/rest/square/queryPromotionGoods', // 为你推荐商品
  notProxySendNum: '/api/saleorder/rest/order/smallCustomer/list', // 未代发数
  returnOrderNum: '/api/mall/rest/return/order/num', // 待退货单数
  findVendorRecentlyGoodsInfo: '/api/mall/rest/square/findVendorRecentlyGoodsInfo', // 获取指定档口的最近上新款数据

  // 店铺过期授权
  shopExpireList: '/api/baseinfo/rest/shop/expire',
  shopAgain: '/api/baseinfo/rest/shop/auth/again',

  // 查询收货单
  receiveOrder: '/api/wms/rest/receive_order/listByPage',

  // 查询收货单明细
  receiveOrderDetail: '/api/wms/rest/receive_order/pageQueryDetail',

  // 同意或拒绝档口送货
  accetpReveive: '/api/wms/rest/receive_order/acceptOrRefuse/receive',

  // 收货单修改备注

  updateRemark: '/api/wms/rest/receive_order/editRemark',

  // 仓库
  getWarehouses: '/api/pos/baseinfo/rest/shop/findPosShopWarehoue',

  // boss用户
  anonSend: '/api/iac/validCode/anon/boss/send', // 发送验证码
  modifyPhone: '/api/iac/user/modify/phone', // 修改手机号
  getUnReadList: '/api/boss/baseinfo/rest/message/unRead/num', // 未读消息数量
  getBossMessageList: '/api/boss/baseinfo/rest/message/page',

  // 拿货系统首页
  headOverview: '/api/pgms/rest/pickup/index/head/overview', // 表头统计
  userProcess: '/api/pgms/rest/pickup/index/user/process', // 拿货员
  orderProcess: '/api/pgms/rest/pickup/index/order/process', // 拿货单
  vendorProcess: '/api/pgms/rest/pickup/index/vendor/process', // 供应商进度
  outlierSku: '/api/pgms/rest/pickup/index/outlier/sku', // 异常sku
  pgmsSite: '/api/pgms/rest/pickup/index/site', // 站点
  returnHeaderOverview: '/api/pgms/rest/return/index/head/overview', // 退货表头数据
  returnOrderProcess: '/api/pgms/rest/return/index/order/process', // 退货单进度
  returnUserProcess: '/api/pgms/rest/return/index/user/process', // 退货员进度
  returnVendorProcess: '/api/pgms/rest/return/index/vendor/process', // 退货供应商进度

  // pos dashboard
  getAllMenus: '/api/iac/resource/dashboard/single/menu', // 首页-所有快捷入口
  getShortCutMenus: '/api/iac/resource/dashboard/menu', // 首页-快捷入口
  findArrearsInfo: '/api/pos/rest/homepage/findArrearsInfo', // 首页-欠款信息
  findRecentlyDataView: '/api/pos/rest/homepage/findRecentlyDataView', // 首页-近七日数据总览
  findNoticeByParam: '/api/pos/rest/notice/findByParam', // 首页-获取公告/系统更新
  findNoticeRecently: '/api/pos/rest/notice/findByParamRecently', // 首页-获取公告/系统更新最近一条
  findReportForm: '/api/pos/rest/homepage/findReportForm', // 首页-近七日报表数据
  findTopFrom: '/api/pos/rest/homepage/findTopFrom', // 首页-TOP5报表数据
  findTopRank: '/api/pos/rest/homepage/findTopRank', // 首页-款式编号TOP5数据
  queryNoticeList: '/api/pos/rest/notice/queryNoticeList', // 公告列表
  queryNoticeDetail: '/api/pos/rest/notice/queryNotice', // 公告详情
  addNoticeViews: '/api/pos/rest/notice/addNoticeViews', // 增加公告浏览量
  // 以下三个接口已废弃
  getMessageTypes: '/api/pos/baseinfo/rest/message/getPageList', // 通知中心-消息类型
  queryMessageList: '/api/pos/baseinfo/rest/message/querySaleMessageVo', // 通知中心-消息列表
  readAllMessage: '/api/pos/baseinfo/rest/message/done', // 消息全部已读

  homePageTypes: '/api/iac/resource/homePage/types', // 获取角色
  getCoordination: '/api/pos/rest/homepage/yLCoordination', // 首页依链协同模块统计
  getPosDeliver: '/api/pos/rest/homepage/yLPosDeliver', // 首页依链代发模块统计
  getShopOperate: '/api/pos/rest/homepage/yLShopOperate', // 首页依链小票模块统计
  getPosOrder: '/api/pos/rest/homepage/yLPosSaleOrder', // 首页开单模块统计
  getShowSpecialHome: '/api/pos/rest/homeParamSys/show/special/home/page', // 查询当前用户是否展示特殊首页

  // boss首页
  settingExist: '/api/boss/baseinfo/rest/message/setting/exist', // 消息通知设置是否存在

  // 通知
  getSystemGroup: '/api/boss/baseinfo/notification/rest/message/systemGroup', // 查询系统下group
  batchReadMsg: '/api/boss/baseinfo/notification/rest/message/batchReadMsg', // 批量已读消息
  getMessageList: '/api/boss/baseinfo/notification/rest/message/page', // 分页查询消息
  getNotReadMessageStatistics: '/api/boss/baseinfo/notification/rest/message/statistics/notRead', // 统计未读消息数量
  closeImportantMsg: '/api/boss/baseinfo/notification/rest/message/closeImportantMsg', // 关闭重要消息

  getPosExpireTenantMsg: '/api/tenant/tenantRecharge/getPosExpireTenantMsg', // 查询POS充值情况
};
