export const ORDER_LIST = [
  {
    label: '今日新订单',
    value: 'mallSaleOrderNum',
    showTip: false,
    count: 0,
    tipText: '',
    url: '/egenie-boss/chooseStyleOrder',
    messageType: 1,
    pageId: 60050,
  },
  {
    label: '售后中订单',
    value: 'mallReturnOrderNum',
    showTip: true,
    count: 0,
    tipText: '部分商品申请售后的待发货订单',
    url: '/egenie-boss/chooseStyleOrder',
    messageType: 2,
    pageId: 60050,
  },
  {
    label: '24h揽收即将超时',
    value: 'mallCollectToTimeOutNum',
    showTip: true,
    count: 0,
    tipText: '即将超过24小时揽收时限的订单',
    url: '/egenie-boss/chooseStyleOrder',
    messageType: 5,
    pageId: 60050,
  },
  {
    label: '24h揽收已超时',
    value: 'mallCollectTimeOutNum',
    showTip: true,
    count: 0,
    tipText: '已超过24小时揽收时限的订单',
    url: '/egenie-boss/chooseStyleOrder',
    messageType: 6,
    pageId: 60050,
  },
  {
    label: '48h揽收即将超时',
    value: 'mallCollectToTimeOutNumInTwoDay',
    showTip: true,
    count: 0,
    tipText: '即将超过48小时揽收时限的订单',
    url: '/egenie-boss/chooseStyleOrder',
    messageType: 3,
    pageId: 60050,
  },
  {
    label: '48h揽收已超时',
    value: 'mallCollectTimeOutNumInTwoDay',
    showTip: true,
    count: 0,
    tipText: '已超过48小时揽收时限的订单',
    url: '/egenie-boss/chooseStyleOrder',
    messageType: 4,
    pageId: 60050,
  },
];
export const SALES_ORDER_LIST = [
  {
    label: '今日新订单',
    value: 'retailSaleOrderNum',
    showTip: false,
    count: 0,
    tipText: '',
    url: '/egenie-boss/chooseStyleSaleOrder',
    messageType: 1,
    pageId: 60051,
  },
  {
    label: '售后中订单',
    value: 'retailReturnOrderNum',
    showTip: true,
    count: 0,
    tipText: '部分商品申请售后的待发货订单',
    url: '/egenie-boss/chooseStyleSaleOrder',
    messageType: 2,
    pageId: 60051,
  },
  {
    label: '24h揽收即将超时',
    value: 'retailCollectToTimeOutNum',
    showTip: true,
    count: 0,
    tipText: '即将超过24小时揽收时限的订单',
    url: '/egenie-boss/chooseStyleSaleOrder',
    messageType: 5,
    pageId: 60051,
  },
  {
    label: '24h揽收已超时',
    value: 'retailCollectTimeOutNum',
    showTip: true,
    count: 0,
    tipText: '已超过24小时揽收时限的订单',
    url: '/egenie-boss/chooseStyleSaleOrder',
    messageType: 6,
    pageId: 60051,
  },
  {
    label: '48h揽收即将超时',
    value: 'retailCollectToTimeOutNumInTwoDay',
    showTip: true,
    count: 0,
    tipText: '即将超过48小时揽收时限的订单',
    url: '/egenie-boss/chooseStyleSaleOrder',
    messageType: 3,
    pageId: 60051,
  },
  {
    label: '48h揽收已超时',
    value: 'retailCollectTimeOutNumInTwoDay',
    showTip: true,
    count: 0,
    tipText: '已超过48小时揽收时限的订单',
    url: '/egenie-boss/chooseStyleSaleOrder',
    messageType: 4,
    pageId: 60051,
  },
];

export const AFTER_SALES_ORDER_LIST = [
  {
    label: '待审核',
    value: 'mallReturnOrderToAuditNum',
    showTip: true,
    count: 0,
    tipText: '已提交申请但未审核的售后单',
    url: '/egenie-boss/afterSalesService/index',
    messageType: 7,
    pageId: 60055,
  },
  {
    label: '审核已超时',
    value: 'mallReturnOrderAuditTimeOutNum',
    showTip: true,
    count: 0,
    tipText: '已超过24小时审核时限的售后单',
    url: '/egenie-boss/afterSalesService/index',
    messageType: 8,
    pageId: 60055,
  },
  {
    label: '退款即将超时',
    value: 'mallReturnOrderRefundToTimeOutNum',
    showTip: true,
    count: 0,
    tipText: '即将超过24小时确认退款的售后单（网仓已收货）',
    url: '/egenie-boss/afterSalesService/index',
    messageType: 9,
    pageId: 60055,
  },
  {
    label: '退款已超时',
    value: 'mallReturnOrderRefundTimeOutNum',
    showTip: true,
    count: 0,
    tipText: '已超过24小时确认退款的售后单（网仓已收货）',
    url: '/egenie-boss/afterSalesService/index',
    messageType: 10,
    pageId: 60055,
  },
];
export const AFTER_SALES_SALE_ORDER_LIST = [
  {
    label: '待审核',
    value: 'retailReturnOrderToAuditNum',
    showTip: true,
    count: 0,
    tipText: '已提交申请但未审核的售后单',
    url: '/egenie-boss/distributionAfterSale',
    messageType: 7,
    pageId: 60057,
  },
  {
    label: '审核已超时',
    value: 'retailReturnOrderAuditTimeOutNum',
    showTip: true,
    count: 0,
    tipText: '已超过24小时审核时限的售后单',
    url: '/egenie-boss/distributionAfterSale',
    messageType: 8,
    pageId: 60057,
  },
  {
    label: '退款即将超时',
    value: 'retailReturnOrderRefundToTimeOutNum',
    showTip: true,
    count: 0,
    tipText: '即将超过24小时确认退款的售后单（网仓已收货）',
    url: '/egenie-boss/distributionAfterSale',
    messageType: 9,
    pageId: 60057,
  },
  {
    label: '退款已超时',
    value: 'retailReturnOrderRefundTimeOutNum',
    showTip: true,
    count: 0,
    tipText: '已超过24小时确认退款的售后单（网仓已收货）',
    url: '/egenie-boss/distributionAfterSale',
    messageType: 10,
    pageId: 60057,
  },
];

export const PAGE_TYPE = {
  '1': {
    url: '/egenie-boss/chooseStyleOrder',
    name: '选款采购订单',
    pageId: 60050,
  },
  '2': {
    url: '/egenie-boss/chooseStyleSaleOrder',
    name: '选款分销订单',
    pageId: 60051,
  },
  '3': {
    url: '/egenie-boss/afterSalesService/index',
    name: '选款售后订单',
    pageId: 60055,
  },
  '4': {
    url: '/egenie-boss/distributionAfterSale',
    name: '分销售后订单',
    pageId: 60057,
  },
};
