import { Pagination, Empty, Checkbox, Spin } from 'antd';
import { observer } from 'mobx-react';
import React, { useEffect, useMemo } from 'react';
import { api } from '../../../utils/api';
import styles from './index.less';
import commonStyles from '../bossDashBoard/index.less';
import NotificationCenterStore from './store';
import { PAGE_TYPE } from '../bossDashBoard/constant';

const NotificationCenter = observer(() => {
  const store = useMemo(() => {
    return new NotificationCenterStore();
  }, []);

  const { messageTypeList, loading, isRead, messageType, data, page, pageSize, total, onChangeActiveTab, onPaginationChange, changeCheckBox, setAllRead, queryMessageList, hasReadMessage } = store;

  const gotoDetail = (type: number, messageType: number, id: number): void => {
    hasReadMessage(id);
    top.egenie.openTab(`${PAGE_TYPE[type]?.url}?messageType=${messageType}`, '__notice_check', `${PAGE_TYPE[type]?.name}`);
  };

  useEffect(() => {
    queryMessageList();
  }, []);

  return (
    <div className={styles.noticeCenterWrap}>
      <Spin spinning={loading}>
        <div className={styles.header}>
          <h2>
            消息中心
          </h2>
          <Checkbox
            checked={isRead}
            onChange={changeCheckBox}
          >
            <span
              className={`${commonStyles.fs12} ${commonStyles.color333} ${commonStyles.fw400}`}
              onClick={() => {
                queryMessageList({
                  page: 1,
                  status: 0,
                });
              }}
            >
              仅查看未读消息
            </span>
          </Checkbox>
          <div
            className={`${styles.allRead} ${commonStyles.fs12} ${commonStyles.color333} ${commonStyles.fw400}`}
            onClick={() => {
              setAllRead();
            }}
          >
            <img
              alt=""
              height={14}
              src={`${api.oss}/images/hasRead.png`}
              width={14}
            />
            <span style={{ marginLeft: 4 }}>
              全部已读
            </span>
          </div>
        </div>
        <div className={styles.notificationCenter}>
          <div className={styles.left}>
            <div
              className={styles.tabs}
            >
              {messageTypeList.map((item) => (
                <div
                  className={messageType === item.value ? `${styles.tabItem} ${styles.tabItemActive}` : styles.tabItem}
                  key={item.value}
                  onClick={() => onChangeActiveTab(item.value)}
                >
                  {item.label}
                  {item.count > 0 ? (
                    <span className={styles.unreadNum}>
                      {item.count > 99 ? '99+' : item.count}
                    </span>
                  ) : null}
                </div>
              ))}
            </div>
          </div>
          <div className={styles.right}>
            {data.length > 0 ? (
              <div className={styles.messageListWrap}>
                {
                  data?.map((item) => {
                    const { id, status, title, createTime, messageType, pageType } = item;
                    return (
                      <div
                        className={styles.messageItem}
                        key={id}
                        onClick={() => {
                          gotoDetail(pageType, messageType, id);
                        }}
                      >
                        <div
                          className={`${styles.messageHeader} ${commonStyles.fs14} ${commonStyles.fw500} ${status ? commonStyles.colorLight : commonStyles.colorHead} ${commonStyles.mb4}`}
                        >
                          <div style={{
                            display: 'flex',
                            alignItems: 'center',
                          }}
                          >
                            {' '}
                            {!status && <div className={styles.noReadTag}/>}
                            <div>
                              {title}
                            </div>
                          </div>
                          <div
                            className={`${styles.messageTime} ${commonStyles.fs12} ${commonStyles.fw400} ${commonStyles.colorLight}  ${commonStyles.mb12} `}
                          >
                            {createTime}
                          </div>
                        </div>
                        <div
                          className={`${styles.messageInfo} ${commonStyles.fs12} ${commonStyles.fw400} ${commonStyles.colorHead}  ${commonStyles.mb8}`}
                          style={{ color: !status ? '#2b2e3e' : '#999ba4' }}
                          title={item.messageContents?.[0]?.value}
                        >
                          {item.messageContents?.[0]?.value}

                        </div>
                        <div
                          className={styles.checkBtn}
                        >
                          <span>
                            查看
                          </span>
                          <i className="icon-sq"/>
                        </div>
                      </div>
                    );
                  })
                }
              </div>
            ) : null}
            {
              (!loading && data.length === 0) && (
                <div className={styles.empty}>
                  <Empty
                    description="暂无数据哦！"
                    image={`${api.oss}/images/empty.png`}
                  />
                </div>
              )
            }
            <div className={styles.footer}>
              <Pagination
                current={page}
                onChange={onPaginationChange}
                pageSize={pageSize}
                showQuickJumper
                showSizeChanger
                showTotal={(totalCount: number) => `共${totalCount}条记录`}
                total={total}
              />
            </div>
          </div>
        </div>
      </Spin>
    </div>
    
  );
});

export default NotificationCenter;
